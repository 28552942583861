import React, { useEffect } from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'
import AOS from 'aos';


import footerlogo from "../../assets/images/footerlogo.png";
import footerbottombg from "../../assets/images/footerbottombg.png";

import { FaFacebook } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaDiscord } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";



function Footer() {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <React.Fragment>
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6" data-aos="fade-right"
                            data-aos-duration="1000">
                            <div className="logo">
                                <img src="/images/logo.svg" alt="logo" />
                            </div>
                            <p>At <span>Quick Growth</span>, we offer affordable and comprehensive solutions, that empower drive growth, and enhance efficiency to meet your unique needs.</p>
                            <div className="social">
                                <Link to=""><img src="/images/footer/social1.svg" alt="socialIcon" /></Link>
                                <Link to=""><img src="/images/footer/social2.svg" alt="socialIcon" /></Link>
                                <Link to=""><img src="/images/footer/social3.svg" alt="socialIcon" /></Link>
                                <Link to=""><img src="/images/footer/social4.svg" alt="socialIcon" /></Link>
                                <Link to=""><img src="/images/footer/social5.svg" alt="socialIcon" /></Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-down"
                            data-aos-duration="1000">
                            <div className="links">
                                <h4>Company</h4>
                                <ul>
                                    <li><Link to="/about-us">About Us</Link></li>
                                    <li><Link to="/contact-us">Location</Link></li>
                                    <li><Link to="/faqs">FAQs</Link></li>
                                    <li><Link to="/contact-us">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-down"
                            data-aos-duration="1000">
                            <div className="links">
                                <h4>Services</h4>
                                <ul>
                                    <li><Link to="">Artificial Intelligence</Link></li>
                                    <li><Link to="/services/web/website-design-development">Web Development</Link></li>
                                    <li><Link to="">Software Development</Link></li>
                                    <li><Link to="/services/digital-marketing/seo">Digital Marketing</Link></li>
                                    <li><Link to="/services/branding">Branding</Link></li>
                                    <li><Link to="/services/hosting">Hosting</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="fade-left"
                            data-aos-duration="1000">
                            <div className="links">
                                <h4>Join our Newsletter</h4>
                                <div className="formGroup">
                                    <input type="email" name="email" id="email" placeholder="Enter Your Email" />
                                    <button>Subscribe</button>
                                </div>
                                <p>We will send you weekly updates for your better Product management.</p>
                            </div>
                        </div>
                    </div>
                    <div className="copRight">
                        <p>Copyright 2024 <span>Quick Growth.</span></p>
                    </div>
                </div>
            </div> */}



            <footer className="position-relative newfooter pt-5 overflow-hidden">
                <div className="container">
                    {/* Top Section */}
                    <div className="text-center mb-5">
                        {/* Logo */}
                        <div className="d-flex justify-content-center mb-4">
                            <img
                                src={footerlogo}
                                alt="QuickGrowth Logo"
                                className="img-fluid"
                                style={{ width: "159px", height: "98px" }}
                            />
                        </div>
                        {/* Paragraph */}
                        <p className=" mx-auto" style={{ maxWidth: "744px" }}>
                            At Quick Growth, we offer affordable and comprehensive solutions
                            that empower, drive growth, and enhance efficiency to meet your
                            unique needs.
                        </p>
                    </div>

                    {/* Middle Section */}
                    <div className="row text-center text-md-start g-4">
                        {/* Company Links */}
                        <div className="col-md-3 footerbox">
                            <h4 className="footertitle">Company</h4>
                            <ul className="list-unstyled">
                                <li><Link to="#" className="">About Us</Link></li>
                                <li><Link to="#" className="">Location</Link></li>
                                <li><Link to="#" className="">FAQs</Link></li>
                                <li><Link to="#" className="">Contact</Link></li>
                            </ul>
                        </div>

                        {/* Services Links */}
                        <div className="col-md-3 footerbox">
                            <h4 className="footertitle">Services</h4>
                            <ul className="list-unstyled">
                                <li><Link to="#" className="">Artificial Intelligence</Link></li>
                                <li><Link to="#" className="">Web Development</Link></li>
                                <li><Link to="#" className="">Software Development</Link></li>
                                <li><Link to="#" className="">Digital Marketing</Link></li>
                                <li><Link to="#" className="">Branding</Link></li>
                            </ul>
                        </div>

                        {/* Newsletter Section */}
                        <div className="col-md-6  Newsletter footerbox">
                            <h4 className="footertitle">Join Our Newsletter</h4>
                            <p className=" mt-2 mb-4">
                                We will send you weekly updates for better product management.
                            </p>
                            <form className="">
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    className="form-control rounded-pill"
                                />
                                <button type="submit" className="btn btn-primary rounded-pill px-4">
                                    Send
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Bottom Section */}
                <div
                    className="py-3 mt-5 footerbottom text-white text-center text-md-start"
                    style={{ backgroundImage: `url(${footerbottombg})`, backgroundSize: "cover" }}
                >
                    <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center">
                        <p className="mb-0 text-uppercase">Copyright 2024 Quick Growth.</p>
                        <div className="d-flex sociallink gap-3 mt-3 mt-md-0">
                            <a href="#" >
                                <FaFacebook />
                            </a>
                            <a href="#">
                                <PiInstagramLogoFill />
                            </a>
                            <a href="#">
                                <FaDiscord />
                            </a>
                            <a href="#">
                                <FaTwitter />
                            </a>
                            <a href="#">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer
